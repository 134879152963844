// react-flags-select //
.react-flags-select {
    button {
        display: inline;
    }
    ul {
        left: initial;
    }
}

.react-flags {
    & > button {
        height: 34px;
        border-radius: 6px;
    }
}

// react-phone-input-2 //
.react-tel-input {
    .form-control {
        width: 100% !important;
        background-color: transparent !important;
    }
}

// react-pdf //
.react-pdf__Document {
    * {
        // Enable text selection in pdf
        user-select: text !important;
        // Disable text selection in pdf
        // user-select: all !important;
        // cursor: default !important;
    }
    .pdf-cursor-crosshair {
        * {
            cursor: crosshair !important;
        }
    }
}

// painterro //
.ptro-holder-wrapper {
    z-index: 100;
}

.ptro-icon-btn {
    border-radius: 27px;
    margin-right: 5px !important;
}

// react-mentions //
.mentions-field__input {
    overflow: auto !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    width: 100%;
    line-height: 1.25;
    color: #374151;
    appearance: none;

    &:focus {
        outline: none;
    }
}

.mentions-field__suggestions {
    position: absolute !important;
    margin-top: 0 !important;
    left: 0;
    top: 100% !important;
    width: 100%;
    border-radius: 0.5rem;
    border-width: 1px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    text-align: left;
    background-color: #ffffff;
    box-shadow:
        0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);

    .mentions-field__suggestions__item {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        &:hover {
            cursor: pointer;
            background-color: #f1f5f9;
        }

        @media (min-width: 640px) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}

.mentions-field__highlighter {
    display: none;
}

.mentions-field--multiLine {
    border-radius: 0.5rem;
}

// @cyntler/react-doc-viewer
#react-doc-viewer {
    #proxy-renderer {
        flex: 1;
        #msdoc-renderer {
            height: 100%;
        }
    }
}
