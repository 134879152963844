// Import here for provide sort imports ( @trivago/prettier-plugin-sort-imports ) plugins with prettier on Js files
@import "./antd.scss";
@import "./common.scss";
@import "./libs.scss";
@import "./animations.scss";

// CUSTOM COMMON STYLES //
.inherit {
    position: inherit !important;
}
.font-shuriken {
    font-family: "The Last Shuriken";
}

.font-loading {
    .material-symbols-outlined {
        opacity: 0;
        width: 0px;
        height: 0px;
        line-height: 0px;
    }
}

// SPINNER //
.spinner-overlay {
    z-index: 99998;
    background-color: rgba(0, 0, 0, 0.8);
}

.spinner {
    z-index: 99999;
}

// TODO : OLD CARDS //
.card {
    @apply rounded-lg shadow-lg bg-white border;
}

.card-half-rounded {
    @apply shadow-xl bg-white border p-4 rounded-tl-3xl rounded-br-3xl;
}

.bordered-card {
    @apply rounded-lg shadow-lg bg-slate-50 border-[3px] border-white;
}

// INSTITUTIONALS //
.institutional-content {
    h2 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 1.1rem;
    }
    h3 {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 0.9rem;
        text-decoration: underline;
    }
    a {
        color: #0038cb;
    }
}

// COOKIES //
.cookies-banner {
    background-color: #f1f5f9 !important;
}

.cookies-accept-btn {
    background-color: #0038cb !important;
    color: #fff !important;
    border: 2px solid #0038cb !important;
    border-radius: 10px !important;
}

.cookies-decline-btn {
    background-color: transparent !important;
    color: #0038cb !important;
    border: 2px solid #0038cb !important;
    border-radius: 10px !important;
}

// VISA BADGE //
.visa-badge {
    @apply inline-block rounded-md text-2xs font-bold px-1 border-2;
    &.blue {
        @apply border-primary text-primary;
    }
    &.yellow {
        @apply border-yellow-400 text-yellow-400;
    }
    &.red {
        @apply border-red-500 text-red-500;
    }
    &.fill-green {
        @apply bg-emerald-400 border-2 border-emerald-400 text-white;
    }
    &.fill-red {
        @apply bg-red-500 border-2 border-red-500 text-white;
    }
    &.fill-yellow {
        @apply bg-yellow-400 border-2 border-yellow-400 text-white;
    }
    &.fill-fuchsia {
        @apply bg-fuchsia-500 border-2 border-fuchsia-500 text-white;
    }
}

// PHOTOS //
.animate-screenshot {
    background-color: white;
    transition: all 0s;
}

// SHARE CODE IFRAME //
.code-block {
    background-color: #282a36; /* Dark background */
    color: #f8f8f2; /* Light text */
    font-family: "Fira Code", monospace;
    margin: 0;
    padding: 0;
    code,
    pre {
        font-family: "Fira Code", monospace;
        background-color: #282a36;
        color: #f8f8f2;
        padding: 1em;
        border-radius: 5px;
        display: block;
        overflow-x: auto;
        white-space: pre-wrap;
    }
}

// LANGUAGE SELECTION //
.language-select {
    font-size: 1rem !important;
}

.language-select.border-0 {
    border: 0 !important;
}

.language-select.pb-0 {
    padding-bottom: 0 !important;
}

.language-select ul {
    margin-top: 0 !important;
}

.language-select .text-black {
    color: #000 !important;
}

.language-select .text-white {
    color: #fff !important;
}

.language-select::after {
    display: none !important;
}

// VIEWER //
.viewer-container div:first-of-type {
    z-index: 0;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: url(#loading-spinner-gradient) !important;
}

// FORMS //

.from-nomargin {
    .ant-form-item {
        margin-bottom: 0px;
    }
}

.btn-group-form-state {
    .ant-radio-button-wrapper-checked {
        &:nth-child(1) {
            background-color: var(--ant-color-error) !important;
            border-color: var(--ant-color-error) !important;
        }
        &:nth-child(2) {
            background-color: var(--ant-color-warning) !important;
            border-color: var(--ant-color-warning) !important;
        }
        &:nth-child(3) {
            background-color: var(--ant-color-success) !important;
            border-color: var(--ant-color-success) !important;
        }
    }
}

// ___ //

.background-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.highlighted {
    background-image: url("../../public/img/text-highlighted.svg");
    @extend.background-img;
    background-size: 138%;
    background-position: center 35%;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
}

.dot-before::before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 3px;
    display: inline-block;
    vertical-align: middle;
}

.bg-gradient-colors {
    background-image: linear-gradient(
        to right,
        #3ce1b9,
        #c33cc8,
        #ff0047,
        #c33cc8,
        #0038cb
    );
}

#tabs-automations {
    height: 100%;
    .ant-tabs-content-holder {
        height: 100%;
        .ant-tabs-content {
            height: 100%;
            .ant-tabs-tabpane {
                height: 100%;
            }
        }
    }
}

.search-item {
    .ant-collapse-header {
        padding: 0 !important;
    }
}

.orange-btn {
    font-weight: normal;
    padding: 30px 20px;
    border-radius: 0;
    border: 1px solid #ff4002;
    background: rgba(255, 64, 2, 0.2);
    transition: background 0.3s ease;

    &:hover {
        color: inherit !important;
        border: 1px solid #ff4002 !important;
        background: rgba(255, 64, 2, 0.5) !important;
    }
}
